 <template>
	<el-dialog top="1vh" title="过路费结算" width="1050px" :visible.sync="is_show_in_page">
		<el-form class="form_ordinary" label-width="97px">

			<div class="big_tit" style="margin:0">打款数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主信息" style="width:588px">
					<el-input class="el_inner_width" v-model="shipper_info_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货主付款" style="width:388px">
					<el-input class="el_inner_width" v-model="this_payed_text" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="承运人信息" style="width:588px">
					<el-input class="el_inner_width" v-model="truck_owner_info_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="承运人收款" style="width:388px">
					<el-input class="el_inner_width" v-model="this_settled_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">打款信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="平台付款卡" style="width:588px">
					<el-select class="el_inner_width" v-model="platform_bank_card_choosed">
						<el-option v-for="(item,index) in platform_bank_card_list" :key="index" :label="item.card_num+' / '+item.bank_cname" :value="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="付款流水编号" style="width:388px">
					<el-input class="el_inner_width" v-model="platform_bank_ra_num" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="打款凭证" style="width:588px">
					<div class="voucher" @click="voucher_choose()">
						<img v-if="voucher.src" :src="voucher.src" class="img">
						<i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
					</div>
				</el-form-item>
				<el-form-item class="el_form_item" label="打款备注" style="width:388px">
					<el-input class="el_inner_width" v-model="mark" clearable></el-input>
				</el-form-item>
			</div>


		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub()">提交</el-button>
		</div>

	</el-dialog>
</template>

<script>
	export default {
		props:{
			tord_info:Object,
			payed_info:Object,
			is_show:Number
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//货主信息
				shipper_info_text:'',

				//货主付款
				this_payed_text:'',

				//收款人字符串
				truck_owner_info_text:'',

				//打款金额字符串
				this_settled_text:'',

				//平台可用银行卡列表
				platform_bank_card_list:[],

				//平台可用银行卡选择结果
				platform_bank_card_choosed:'',

				//打款流水编号
				platform_bank_ra_num:'',

				//打款备注
				mark:"第一次测试",

				//打款凭证
				voucher:{
					src: '', //图片地址
					key: '', //图片key
					is_choose: false,
					is_upl: false,
					bucket: 'tordrealtimeoutcashvoucher',
				},
				
				//提交锁
				sub_swh:false,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.is_show_in_page=false
				}else {
					this.is_show_in_page=true
					this.init();
				}
			},
		},
		methods: {

			//提交
			sub(){

				if(this.sub_swh){
					this.$my.other.msg({
						type:'warning',
						str:"提交中, 请稍等"
					});
					return;
				}

				if(!this.$my.check.is_num(this.platform_bank_card_choosed)){
					this.$my.other.msg({
						type:'warning',
						str:"请选择平台付款卡"
					});
					return;
				}

				if(!this.platform_bank_ra_num.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少付款流水编号"
					});
					return;
				}

				if(!this.voucher.is_choose){
					this.$my.other.msg({
						type:'warning',
						str:"请上传打款凭证"
					});
					return;
				}

				let that=this;
				let send=function(){

					//锁定
					that.sub_swh=true;

					that.$my.net.req({
						data:{
							mod:'truck_tord_real_time',
							ctr:'tord_settle_done_by_admin',
							payed_num:that.payed_info.payed_num,
							truck_tord_num:that.payed_info.truck_tord_num,
							platform_bank_cname:that.platform_bank_card_list[that.platform_bank_card_choosed].bank_cname,
							platform_card_num:that.platform_bank_card_list[that.platform_bank_card_choosed].card_num,
							platform_bank_ra_num:that.platform_bank_ra_num,
							platform_bank_ra_voucher:that.voucher.key,
							mark:that.mark,
						},
						callback:(data)=>{

							//解锁
							that.sub_swh=false;

							//报告结果
							that.$my.other.msg({
								type:'success',
								str:"支付成功"
							});

							//关闭页面
							that.is_show_in_page=false;

							//通知
							that.$emit("settle_end",'3');
						}
					});
				};

				if(this.voucher.is_upl){
					send()
				}else{

					//上传
					this.$my.qiniu.upl({
						bucket: this.voucher.bucket,
						key: this.voucher.key, //xxxxxx?v=1 或者 null
						file_path: this.voucher.src,
						callback: (data) => {
							var key = data.key.split('__v__1')
							this.voucher.is_upl = true;
							this.voucher.key =key[0];
							send()
						}
					});
				}
			},

			//选择打款凭证
			voucher_choose(){

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {
						this.voucher.src = res.src;
						this.voucher.is_choose = true;
						this.voucher.is_upl = false;
					}
				});
			},


			//页面初始化
			init(){

				//收款人字符串
				this.shipper_info_text=this.tord_info.shipper_info.name+' / '+this.tord_info.shipper_info.tel+' / '+this.tord_info.shipper_info.company_name

				//打款金额字符串
				this.this_payed_text=this.payed_info.this_payed+'元'

				//收款人字符串
				this.truck_owner_info_text=this.tord_info.truck_owner_info.name+' / '+this.tord_info.truck_owner_info.tel

				//打款金额字符串
				this.this_settled_text=this.payed_info.this_settled+'元'

				//可用银行卡列表初始化
				this.platform_bank_card_list=[];
				
				//可用银行卡选择结果初始化
				this.platform_bank_card_choosed=''

				//打款流水编号初始化
				this.platform_bank_ra_num=''

				//打款备注初始化
				this.mark=""

				//提交锁
				this.sub_swh=false;

				//打款凭证初始化
				this.voucher.src=''
				this.voucher.key=''
				this.voucher.is_choose=false
				this.voucher.is_upl=false

				//读取车主的可用提现银行卡
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'platform_bankcard_list_admin',
						use_for_outcash:1,//是否用来出金(1:是,2:不是)
					},
					callback:(data)=>{
						
						//置入可用银行卡列表
						this.platform_bank_card_list=data.list
					}
				});
				this.$emit("loading_close");
			},
		}
	}
</script>

<style lang="scss" scoped>
	.voucher{
		cursor: pointer;
		border: 1px solid #DCDFE6;
		border-radius:4px;
		text-align: center;
		height:250px;
		width:94%;
		.img{
			max-width:calc(100% - 10px);
			max-height:calc(100% - 10px);
			padding:5px;
		}

		.icon{
			line-height: 250px;
			font-size: 80px;
			color: #ccc;
		}
	}

	.bottom_btns{
		text-align: center;
		margin-top: 30px;
	}

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.form_ordinary{
		padding: 10px 0;
		overflow-y:auto;
	}
</style>