 <template>
	<el-dialog top="1vh" title="运费结算" width="1050px" :visible.sync="is_show_in_page">
		<el-form class="form_ordinary" label-width="68px" style="padding:0">

			<div class="big_tit" style="margin:0">运单信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地" style="width:50%">
					<el-input class="el_inner_width" v-model="tord_info_in_page.case_addr" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="卸货地" style="width:50%">
					<el-input class="el_inner_width" v-model="tord_info_in_page.aim_addr" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物信息" style="width:50%">
					<el-input class="el_inner_width" v-model="tord_info_in_page.cargo_info" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="总运费" style="width:50%">
					<el-input class="el_inner_width" v-model="tord_info_in_page.freight_info" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">打款数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主信息" style="width:588px">
					<el-input class="el_inner_width" v-model="shipper_info_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货主付款" style="width:388px">
					<el-input class="el_inner_width" v-model="this_payed_text" disabled></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="收款人" style="width:588px">
					<el-input class="el_inner_width" v-model="settle_target_info_text" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="收款" style="width:388px">
					<el-input class="el_inner_width" v-model="this_settled_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">{{bankcard_list_big_title}}</div>
			<div class="tab1_inner">
				<el-table :data="bank_card_list" :border="true" :stripe="true" size="small" height="250px">
					<el-table-column label="银行卡号信息">
						<template slot-scope="scope">
							<div>{{scope.row.card_num}} / {{scope.row.bank_cname}} / {{scope.row.bank_ename}}</div>
						</template>
					</el-table-column>
					<el-table-column label="银行卡归属人信息及关系">
						<template slot-scope="scope">
							<div>{{scope.row.card_user_name}}{{scope.row.card_user_tel?' / '+scope.row.card_user_tel:' / 无联系方式'}}{{scope.row.relation?' / 关系:'+scope.row.relation:''}}</div>
						</template>
					</el-table-column>
					
					<!-- 操作行 -->
					<el-table-column label="操作" width="160px">
						<template slot-scope="scope">
							<el-button @click="cash_settle_open(scope.row)" size="mini" type="text">人工打款</el-button>
							<el-button @click="cash_settle_done_by_hsbank_pre(scope.row)" size="mini" type="text">打款预览</el-button>
							<!-- <el-button @click="cash_settle_done_by_hsbank(scope.row)" style="margin-left:0" size="mini" type="text">徽商出金</el-button>
							<el-button @click="cash_settle_ser_by_hsbank(scope.row)" size="mini" type="text">徽商查询</el-button> -->
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-form>

		<!-- 出金界面 -->
		<cashSettleCreat 
			@cash_settle_end="cash_settle_close"
			:data="settle_creat.data"
			:is_show="settle_creat.is_show" 
		></cashSettleCreat>

	</el-dialog>
</template>

<script>
	import cashSettleCreat from './cash_settle_creat.vue'
	import { Loading } from "element-ui";
	export default {
		props:{
			tord_info:Object,
			payed_info:Object,
			settle_target:String,
			is_show:Number
		},
		components:{
			cashSettleCreat
		},
		data() {
			return {

				//页面是否显示
				is_show_in_page:false,

				//可用银行卡列表
				bank_card_list:[],

				//货主信息
				shipper_info_text:'',

				//货主付款
				this_payed_text:'',

				//结算目标人字符串
				settle_target_info_text:'',

				//打款金额字符串
				this_settled_text:'',

				//银行卡列表标题
				bankcard_list_big_title:'',

				//运单信息
				tord_info_in_page:{
					case_addr:'',
					aim_addr:'',
					cargo_info:'',
					freight_info:'',
				},

				//出金界面
				settle_creat:{
					is_show:0,//是否显示
					data:{},//支付数据
				}
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
					this.is_show_in_page=false
				}else {
					this.is_show_in_page=true
					this.init();
				}
			},
		},
		methods: {
			//打开打款界面
			cash_settle_open(bankcard){

				//置入数据
				this.settle_creat.data={
					payed_num:this.payed_info.payed_num,
					truck_tord_num:this.payed_info.truck_tord_num,
					bankcard:bankcard,
					this_settled:this.payed_info.this_settled,
					settle_user_info:this.settle_target_info_text,
					settle_target:this.settle_target,
				};

				//打开界面
				this.settle_creat.is_show++;		
			},
			cash_settle_ser_by_hsbank(bankcard){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'ser_pay_result_by_hsbank',
						payed_num:this.payed_info.payed_num,
						settle_target:this.settle_target,
					},
					callback:(data)=>{
						
						this.$my.other.msg({
							type:"success",
							str:'打款成功'
						});
						
					}
				});
			},
			cash_settle_done_by_hsbank_pre(bankcard){
				console.log(bankcard);
				let that=this;
				
				//发起徽商银行打款
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'payed_by_hsbank',
						payed_num:this.payed_info.payed_num,
						bankcard:bankcard,
						this_settled:this.payed_info.this_settled,
						settle_user_info:this.settle_target_info_text,
						settle_target:this.settle_target,
						pre:1
					},
					callback:(data)=>{
						// console.log(data);
						
						this.$my.other.msg({
							type:"success",
							str:'提交成功，请查询打款结果'
						});
					}
				});
			},
			cash_settle_done_by_hsbank(bankcard){
				console.log(bankcard);
				let that=this;
				
				this.$my.other.confirm({
						content:'确定要通过徽商银行出金吗？',
						confirm:()=>{
		
							//发起徽商银行打款
							this.$my.net.req({
								data:{
									mod:'truck_tord_real_time',
									ctr:'payed_by_hsbank',
									payed_num:this.payed_info.payed_num,
									bankcard:bankcard,
									this_settled:this.payed_info.this_settled,
									settle_user_info:this.settle_target_info_text,
									settle_target:this.settle_target,
								},
								callback:(data)=>{
									// console.log(data);
									
									this.$my.other.msg({
										type:"success",
										str:'提交成功，请查询打款结果'
									});
								}
							});
							
				// 			//执行删除
				// 			this.$my.net.req({
				// 				data:{
				// 					mod:'truck_tord_real_time',
				// 					ctr:'tord_voucher_del',
				// 					voucher_id:this.list[index].voucher_id,
				// 				},
				// 				callback:(data)=>{
				
				// 					//刷新数据
				// 					this.load_data()
				// 				}
				// 			});
						}
					});
				
			},
			cash_settle_close(){//关闭打款界面

				//打款界面关闭后
				this.settle_creat.is_show=0;

				//清空打款界面数据
				this.settle_creat.data={};

				//关闭当前界面
				this.is_show_in_page=false;

				//通知上个界面
				this.$emit("settle_end",'1');
			},


			//页面初始化
			init(){
				//货主字符串
				this.shipper_info_text=`${this.tord_info.shipper_info.name} / ${this.tord_info.shipper_info.tel} / ${this.tord_info.shipper_company_info.name}`

				//打款金额字符串
				this.this_payed_text=this.payed_info.this_payed+'元'

				//运单信息整理
				let case_other=JSON.parse(this.tord_info.case_other)
				let aim_other=JSON.parse(this.tord_info.aim_other)
				this.tord_info_in_page.case_addr=`${this.tord_info.case_city}/${this.tord_info.case_county}/${case_other.addr_info}`
				this.tord_info_in_page.aim_addr=`${this.tord_info.aim_city}/${this.tord_info.aim_county}/${aim_other.addr_info}`
				this.tord_info_in_page.cargo_info=`${this.tord_info.cargo_name}/${this.tord_info.cargo_type}/${this.tord_info.cargo_weight}吨/${this.tord_info.cargo_volume}方`
				this.tord_info_in_page.freight_info=`${this.tord_info.cost_info.freight_total}元`

				//结算目标处理
				if(this.settle_target=='truck_owner'){//结算给车队长

					//车队长字符串
					this.settle_target_info_text=`${this.tord_info.truck_owner_info.name} / ${this.tord_info.truck_owner_info.tel} / 车队长`

					//打款金额字符串
					this.this_settled_text=`${this.payed_info.this_settled_of_truck_owner} 元`

					//银行卡列表标题
					this.bankcard_list_big_title="车队长银行卡"

					//用于读取可用提现银行卡的用户编号
					var bankcard_list_read_user_num=this.payed_info.truck_owner_num

				}else if(this.settle_target=='driver'){//结算给司机

					//司机字符串
					this.settle_target_info_text=`${this.tord_info.driver_info.name} / ${this.tord_info.driver_info.tel} / 司机`

					//打款金额字符串
					this.this_settled_text=`${this.payed_info.this_settled_of_driver} 元`

					//银行卡列表标题
					this.bankcard_list_big_title="司机银行卡"

					//用于读取可用提现银行卡的用户编号
					var bankcard_list_read_user_num=this.payed_info.driver_num
				}

				//读取车主的可用提现银行卡
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'bankcard_list_admin',
						check_status:2,
						read_type:'simple',//normal simple
						user_num:bankcard_list_read_user_num
					},
					callback:(data)=>{
						
						//置入可用银行卡列表
						this.bank_card_list=data.list
					}
				});
				this.$emit("loading_close");
			},
		}
	}
</script>

<style lang="scss" scoped>
	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.form_ordinary{
		padding: 10px 0;
		overflow-y:auto;
	}
</style>